<template>
	<Layout>
		<v-container>
			<v-row>
				<v-col cols="12">
					<h2> <v-icon large>mdi-circle-edit-outline</v-icon> EDITAR DATOS DE USUARIO</h2>
				</v-col>
			</v-row>
			<v-form ref="form" v-model="formValidity">

				<v-row>
					<v-col cols="12" sm="6">

            <div class="d-flex justify-space-around mb-4" >
              <div>
                <qr-code
                    v-if="esMiembro"
                    :size="150"
                    :text="`https://miembros.svderma.org/validar/${$route.params.id}`"
                    color="#000"
                    bg-color="#fff"
                    error-level="L"
                />
                <div class="code-member">{{user._id}}</div>
              </div>

              <div>
		              <v-switch
		                inset class="ma-2"
		                color="success"
		                v-model="user.verificado"
		                :label="`¿Usuario verificado?: ${user.verificado ? 'SI' : 'NO'}`"
		              ></v-switch>

		              <v-switch
		                inset class="ma-2"
		                color="success"
		                v-model="user.solvente"
		                :label="`Miembro Solvente?: ${user.solvente ? 'SI' : 'NO'}`"
		              ></v-switch>
              	</div>

            </div>


						<v-select
							v-model="user.categoria"
							:items="['Dermatologo Miembro','Dermatologo No-Miembro','Otras Especialidades','Estudiante','Industria']"
							prepend-icon="mdi-focus-field"
							menu-props="auto"
							hide-details
							label="Categoria"
							:rules="[rules.required]"
							@change="addressTitle()"
						/>
						<v-select
							v-if="esMiembro"
							v-model="user.miembro"
							:items="['Titular', 'Activo', 'Afiliado', 'Honorario', 'Honorario Titular', 'Honorario Correspondiente Extranjero']"
							prepend-icon="mdi-focus-field"
							menu-props="auto"
							hide-details
							label="Seleccione Titularidad"
						/>
						<v-text-field
							type="number"
							prepend-icon="mdi-shield-account-outline"
							label="Cedula Identidad"
							:rules="[rules.required]"
							v-model="user.cedula"
							hint="Escriba solo números"
							persistent-hint
						/>
						<v-text-field
							prepend-icon="mdi-account-reactivate-outline"
							label="Nombre"
							v-model="user.nombre"
							:rules="[rules.required]"
						/>
						<v-text-field
							prepend-icon="mdi-account-reactivate-outline"
							label="Apellido"
							v-model="user.apellido"
							:rules="[rules.required]"
						/>

						<v-text-field
							prepend-icon="mdi-email-outline"
							label="Correo electrónico"
							v-model="user.email"
							:rules="[rules.required, rules.email]"
						/>
						<v-text-field
							type="tel"
							prepend-icon="mdi-phone"
							label="Teléfono"
							v-model="user.telefono"
						/>
						<v-textarea
	                dense
	                outlined
	                prepend-icon="mdi-map-marker-radius-outline"
	                v-model="user.direccion"
	                :label="direccionTitulo"
	                :value="user.direccion"
	                rows="3"
	            />
            <v-text-field
                prepend-icon="mdi-home-city-outline"
                label="Ciudad"
                v-model="user.ciudad"
            />
<!--             <v-text-field
                prepend-icon="mdi-city-variant-outline"
                label="Estado"
                v-model="user.estado"
                :rules="[rules.required]"
            /> -->
						<v-select
							v-model="user.estado"
							:items="estados"
							prepend-icon="mdi-city-variant-outline"
							menu-props="auto"
							hide-details
							label="Estado"
							:rules="[rules.required]"
						/>



            <v-text-field
                prepend-icon="mdi-flag-outline"
                label="País"
                v-model="user.pais"
            />

            <v-select
                v-model="user.sexo"
                :items="['Femenino','Masculino']"
                prepend-icon="mdi-circle-slice-4"
                menu-props="auto"
                hide-details
                label="Sexo"
                class="mb-5"
            />
            <div class="my-5">
              <v-divider />
            </div>
            <v-text-field
                color="primary"
                v-model="password"
                label="Contraseña (Deje en blanco si no desea modificar)"
                dense
                outlined />
					</v-col>
					<v-col cols="12" sm="6">

            <v-text-field
                v-if="esOtrasEspecialidades || esIndustria"
                prepend-icon="mdi-account-tie-outline"
                label="Especialidad"
                v-model="user.especialidad"
            />
            <v-text-field
                v-if="!esMiembro && !esEstudiante"
                prepend-icon="mdi-hospital-building"
                label="Lugar de trabajo"
                v-model="user.trabajo"
            />
            <v-text-field
                v-if="esEstudiante"
                prepend-icon="mdi-chair-school"
                label="Lugar de Estudio"
                v-model="user.institucion"
            />


            <div v-if="esMiembro">
              <h3 class="mb-3">INFORMACIÓN PÚBLICA</h3>
              <v-textarea
                  dense
                  outlined
                  prepend-icon="mdi-map-marker-radius-outline"
                  v-model="user.direccion_consulta_principal"
                  label="Dirección de Consulta Principal"
                  :value="user.direccion_consulta_principal"
                  rows="3"
              />
              <v-text-field
                  type="tel"
                  prepend-icon="mdi-phone"
                  label="Teléfono(s) para citas Principal"
                  v-model="user.telefono_consulta_principal"
                  class="mb-5"
              />
              <v-textarea
                  dense
                  outlined
                  prepend-icon="mdi-map-marker-radius-outline"
                  v-model="user.direccion_consulta_secundaria"
                  label="Dirección de Consulta Alternativa"
                  :value="user.direccion_consulta_secundaria"
                  rows="3"
              />
              <v-text-field
                  type="tel"
                  prepend-icon="mdi-phone"
                  label="Teléfono(s) para citas Alternativo"
                  v-model="user.telefono_consulta_secundaria"
              />
							<v-text-field
	                  label="Notas Privadas"
	                  v-model="user.notes"
	              />

              <div class="my-5">
                <v-divider />
              </div>
              <h3>Fotografía</h3>
              <div class="pa-3 d-flex flex-column justify-center align-center">
                <img v-if="file == null" :src="user.photo" width="300" />
                <img v-else :src="image" width="300" />
                <v-sheet dark width="300px">
                  <v-btn block depressed tile color="primary" @click="$refs.boton.click()"><v-icon left>mdi-camera</v-icon> Buscar imagen</v-btn>

                  <input type="file" ref="boton" class="d-none" @change="previewImage($event)">
                </v-sheet>
              </div>
<!-- 							<h3>Ficha IG</h3>
	              <div class="pa-3 d-flex flex-column justify-center align-center">
	                <img :src="user.photoIg" width="300" />
	              </div> -->
            </div>
						<div class="my-5">
							<v-divider />
						</div>
						<v-textarea
							dense
							outlined
							v-model="user.notes"
							label="Notas (Privadas)"
							:value="user.notes"
							rows="3"
					/>

					</v-col>
					<v-col cols="12">
						<div class="text-center my-5">
							<v-btn
								:to="{name: 'UsersList'}"
								text
								color="grey darken-3">
								REGRESAR
							</v-btn>
							<v-btn
								class="mx-3"
								@click.stop="removeUser()"
								outlined
								color="red accent-3">
								ELIMINAR
							</v-btn>
							<v-btn
								x-large
								@click.stop="updateUser()"
								:loading="saving"
								:disabled="!formValidity"
								rounded
								color="primary accent-3">
								ACTUALIZAR
							</v-btn>
						</div>
					</v-col>
				</v-row>

			</v-form>
		</v-container>
	</Layout>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from "axios";
	import store from '@/store';
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'EditUser',
		data: () =>({
			password: '',
			loading: false,
			saving: false,
			formValidity: false,
			direccionTitulo: 'Dirección',
			rules: {
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalido email.'
				},
				password: value => value.length >= 6 || 'Contraseña obligatoria',
			},
			user: {
				cedula: '',
				nombre: '',
				apellido: '',
				sexo: '',
				email: '',
				telefono: '',
				categoria: '',
				miembro: '',
				trabajo: '',
				especialidad: '',
				institucion: '',
				direccion: '',
				ciudad: '',
				estado: '',
				pais: 'Venezuela',
				check_token: '',
				verificado: null,
				photo: null,
				photoIg: null,
				solvente: null,
				notes: ''
			},
      image: null,
      file: null,
			estados: [
				'Amazonas',
				'Anzoategui',
				'Apure',
				'Aragua',
				'Barinas',
				'Bolivar',
				'Carabobo',
				'Cojedes',
				'Distrito Capital',
				'Delta Amacuro',
				'Falcon',
				'Guarico',
				'La Guaira',
				'Lara',
				'Merida',
				'Miranda',
				'Monagas',
				'Nueva Esparta',
				'Portuguesa',
				'Sucre',
				'Tachira',
				'Trujillo',
				'Yaracuy',
				'Zulia',
				'Exterior'
			]

		}),
		mounted(){
			this.getData(this.$route.params.id)
		},
		methods: {
			async getData(id){
				let me = this;
				await axios.get(`user/query?_id=${id}` ,configuration)
					.then(function (response) {
						me.user._id = response.data._id,
						me.user.cedula = response.data.cedula,
						me.user.nombre = response.data.nombre,
						me.user.apellido = response.data.apellido,
						me.user.sexo = response.data.sexo,
						me.user.email = response.data.email,
						me.user.telefono = response.data.telefono,
						me.user.categoria = response.data.categoria,
						me.user.miembro = response.data.miembro,
						me.user.trabajo = response.data.trabajo,
						me.user.especialidad = response.data.especialidad,
						me.user.institucion = response.data.institucion,
						me.user.direccion = response.data.direccion,
						me.user.ciudad = response.data.ciudad,
						me.user.estado = response.data.estado,
						me.user.pais = response.data.pais,
						me.user.check_token = response.data.check_token,
						me.user.verificado = response.data.verificado,
						me.user.solvente = response.data.solvente,
						me.user.photo = response.data.photo || 'https://res.cloudinary.com/svdmqe-cloud/image/upload/v1635886550/pictures/user_ciunta.jpg',
						me.user.photoIg = response.data.photoIg || 'https://res.cloudinary.com/svdmqe-cloud/image/upload/v1635886550/pictures/user_ciunta.jpg',
            me.user.direccion_consulta_principal = response.data.direccion_consulta_principal,
            me.user.telefono_consulta_principal = response.data.telefono_consulta_principal,
            me.user.direccion_consulta_secundaria = response.data.direccion_consulta_secundaria,
            me.user.telefono_consulta_secundaria = response.data.telefono_consulta_secundaria,
						me.user.notes = response.data.notes
					})
					.catch(function (e) {
						console.log(e.message)
					})
			},
			async updateUser() {
				let me = this;
				this.saving = true

        if (this.file != null){
          const response = await this.uploadPhoto(this.file)
          this.user.photo = await response.url
          this.file = null
          this.image = null
        }

// 				me.user.role = (me.user.categoria ==  'Dermatologo Miembro') ? 'MIEMBRO' : 'USER'
// 				me.user.miembro = (me.user.categoria ==  'Dermatologo Miembro') ? me.user.miembro : ''
//
// 				console.log(me.user.categoria)
// 				console.log(me.user.miembro)

				await axios.put('user/update', {
					_id: me.user._id,
					role: (me.user.categoria ==  'Dermatologo Miembro') ? 'MIEMBRO' : 'USER',
					cedula: me.user.cedula,
					nombre: me.user.nombre,
					apellido: me.user.apellido,
					sexo: me.user.sexo,
					email: me.user.email,
					telefono: me.user.telefono,
					categoria: me.user.categoria,
					miembro: (me.user.categoria ==  'Dermatologo Miembro') ? me.user.miembro : '',
					trabajo: me.user.trabajo,
					especialidad: me.user.especialidad,
					institucion: me.user.institucion,
					direccion: me.user.direccion,
					ciudad: me.user.ciudad,
					estado: me.user.estado,
					pais: me.user.pais,
					verificado: me.user.verificado,
					solvente: me.user.solvente,
					password: me.password,
          photo: me.user.photo,
          photoIg: me.user.photoIg,
          direccion_consulta_principal: me.user.direccion_consulta_principal,
          telefono_consulta_principal: me.user.telefono_consulta_principal,
          direccion_consulta_secundaria: me.user.direccion_consulta_secundaria,
          telefono_consulta_secundaria: me.user.telefono_consulta_secundaria,
					notes: me.user.notes
				}, configuration)
					.then(function(response){
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Usuario actualizado satisfactoriamente',
							timeout: 4000
						}, {root: true})
						me.saving = false
						me.password = ''
					})
					.catch(function (e) {
						me.saving = false
						console.log(e.message)
					})
			},
			addressTitle(){
				switch (this.user.categoria) {
					case 'Estudiante':
						return this.direccionTitulo = 'Dirección de Institución';
						break;
					case 'Dermatologo Miembro':
						return this.direccionTitulo = 'Dirección de Trabajo';
						break;
					case 'Dermatologo No-Miembro':
						this.direccionTitulo = 'Dirección de Trabajo';
						break;
					case 'Otras Especialidades':
						this.direccionTitulo = 'Dirección de Trabajo';
						break;

					default:
						return this.direccionTitulo = 'Dirección';
						break;
				}
			},
			async removeUser(){

				await this.$confirm('Seguro de borrar este Registro?... Es irreversible', {
					title: 'Warning',
					color: 'error',
					icon: 'mdi-hand',
					buttonTrueColor: 'primary',
					})
						.then(res => {
							if (res) {
								let me = this;
								axios.post('user/remove', { _id: me.user._id, }, configuration)
									.then(function(response){
										me.$store.commit('SET_ALERT_MESSAGE', {
											show: true,
											color: 'success',
											message: 'Usuario eliminado satisfactoriamente',
											timeout: 4000
										}, {root: true})
										me.$router.push({name: 'Dashboard'});
									})
									.catch(function (e) {
										console.log(e.message)
									})
							}
						})

			},
      previewImage(event){
        const extensionFile = event.target.files[0].type;
        if(extensionFile === 'image/jpeg' || extensionFile === 'image/png'){
          this.file = event.target.files[0]
          const reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = (e) => {
            this.image = e.target.result
          }
        }else{
          alert('Archivo no válido')
          this.file = null
          return
        }
      },
      async uploadPhoto (file) {
        let me = this
        return new Promise(function (resolve, reject) {
          const CLOUDINARY_URL = process.env.VUE_APP_CLOUDINARY_URL;
          const CLOUDINARY_UPLOAD_PRESET = process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;

          let formData = new FormData();
          formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
          formData.append('folder', 'miembros');
          formData.append('file', file);
          let request = new XMLHttpRequest();
          request.open('POST', CLOUDINARY_URL, true);
          request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
          request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {
              let response = JSON.parse(request.responseText);
              resolve(response);
            }
            if (request.status !== 200) {
              let response = JSON.parse(request.responseText);
              let error = response.error.message;
              alert('error, status code not 200 ' + error);
              reject(error);
            }
          };
          request.onerror = (err) => {
            alert('error: ' + err);
            reject(err);
            me.saving = false
          };
          request.send(formData);
        })
      }






		},
		computed: {
		...mapState(['alert']),
			esMiembro(){
				return this.user.categoria == 'Dermatologo Miembro'
			},
			esEstudiante() {
				return this.user.categoria == 'Estudiante';
			},
			esProfesional() {
				return this.user.categoria == 'Dermatologo Miembro' || this.user.categoria == 'Dermatologo No-Miembro' || this.user.categoria == 'Otras Especialidades';
			},
			esDermatologo() {
				return this.user.categoria == 'Dermatologo Miembro' || this.user.categoria == 'Dermatologo No-Miembro';
			},
			esOtrasEspecialidades(){
				return this.user.categoria == 'Otras Especialidades';
			},
			esIndustria(){
				return this.user.categoria == 'Industria';
			},
			bannerHeaderSignup(){
				return Ads.bannerHeaderSignup
			}
		}

	}
</script>

<style lang="scss" scoped>
  .code-member {
    font-size:0.7em;
  }
</style>
